@font-face {
  font-family: Addington-Regular;
  src: url(./fonts/addington/Addington.CF/AddingtonCF-Regular.otf);
}
@font-face {
  font-family: Addington-Bold;
  src: url(./fonts/addington/Addington.CF/AddingtonCF-Bold.otf);
}
@font-face {
  font-family: Addington-DemiBold-Italic;
  src: url(./fonts/addington/Addington.CF/AddingtonCF-DemiBoldItalic.otf);
}

html, body {
  font-size: 15px;
  overflow-x: hidden;
  background-color: #FCF7EB !important;
  font-family: 'Avenir Next', sans-serif !important;
  font-size: 15px !important;
  /*background: linear-gradient(180deg, rgba(251,251,250,1) 0%, rgba(240,240,240,1) 100%) !important;*/
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}
.nav-link {
  font-family: 'Avenir Next', sans-serif;
  color: #fff !important;
  font-size: 15px !important;
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.btn-outline-primary {
  font-family: 'Fractul-Semibold', sans-serif !important;
  border-radius: 0px !important;
  padding: 10px 20px !important;
  letter-spacing: 3px;
}
.form-control {
  font-family: 'Avenir Next', sans-serif !important;
  font-size: 15px !important;
  color: #AC4713 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #B25325 !important;
  border-radius: 0 !important;
  margin-bottom: 15px !important;
  background-color: transparent !important;
}
.col-md-4-ext {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

#service_0, #service_1, #service_3 {
  position: absolute;
}
#proyect_1, #proyect_2, #proyect_3, #proyect_4 {
  position: relative;
}

.hide {
  display: none;
}
.send-background {
  z-index: -1;
}
.send-front {
  z-index: 100;
}
.send-front-90 {
  z-index: 90;
}
.hr-short {
  width: 30px;
  color: #000;
}
.hr-custom {
  width: 100px;
  color: #000;
}
.full-container {
  /*background-color: #fff;*/
  max-width: 100% !important;
  padding: 0 !important;
}
.logo {
  width: 40%;
}
.nav-bar-custom {
  background-color: #AC4713;
  padding: 10px 0 !important;
}
.nav-bar-custom-start {
  background-color: #AC4713 !important;
  padding: 10px 0 !important;
}
.brand-image-start {
  height: 25px;
  cursor: pointer;
}
.home-section {
  padding: 57px 0 0 0!important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  max-width: 100% !important;
  background-color: #FCF7EB;
}
.header-title {
  font-family: 'Argon-Regular', sans-serif;
  color: #898989;
  font-size: 20px;
  text-align: center;
  letter-spacing: 8px;
}
.header-img {
  width: 100%;
}
.section-depts {
  border: 4px solid #B25325;
  padding: 20px 0px !important;
}
.img-100 {
  width: 100%;
}
.p-30 {
  padding: 50px !important;
}
hr {
  margin: 1.3rem 0 !important;
  color: #B25325 !important;
  border: 0;
  border-top: 3px solid !important; 
  opacity: 1 !important;
}
.depts-title {
  font-family: 'Avenir Next', sans-serif;
  font-weight: bolder;
  color: #B25325;
  font-size: 22px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.depts-subtitle {
  font-family: 'Avenir Next', sans-serif;
  font-weight: bolder;
  color: #000;
  font-size: 15px;
  text-align: center;
}
.depts-list {
  font-family: 'Avenir Next', sans-serif;
  color: #000;
  font-size: 15px;
  padding-left: 50px;
}
.depts-legend {
  font-family: 'Addington-DemiBold-Italic', sans-serif;
  color: #B25325;
  font-size: 24px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 30px;
}
.depts-comma-open {
  position: absolute;
  width: 50px;
  right: 0;
  top: 0;
}
.depts-comma-close {
  position: absolute;
  width: 50px;
  left: 0;
  bottom: 0;
}
.title-line {
  width: 40%;
}
.hr-title {
  border-top: 12px solid !important; 
}
.hr-title-alt {
  border-top: 1px solid !important; 
}
.portfolio-gall-container {
  padding-top: 30px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
}
.portfolio-gall-cont-back {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.portfolio-gallery-img {
  width: 100%;
}
.col-md-6-custom {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding: 25px !important;
  display: flex;
}
.pt-0 {
  padding-top: 0px !important;
}
.contact-img {
  width: 150px;
}
.icons-contact {
  font-size: 17px;
  font-weight: bolder;
  color: #B25325;
}
.text-contact {
  font-size: 17px;
  color: #B25325;
}
.btn-outline-primary {
  --bs-btn-color: #B25325 !important;
  --bs-btn-border-color: #B25325 !important;
  --bs-btn-hover-bg: #B25325 !important;
  --bs-btn-hover-border-color: #B25325 !important;
  --bs-btn-active-bg: #B25325 !important;
  --bs-btn-active-border-color: #B25325 !important;
  --bs-btn-disabled-color: #B25325 !important;
}
.btn {
  font-family: 'Argon-Regular', sans-serif;
  font-weight: bold;
  color: #B25325;
}
.maps-title {
  font-weight: bold;
  font-size: 17px;
}
.icons-maps {
  font-size: 25px;
  font-weight: bolder;
  color: #B25325;
}
.ref-icon {
  height: 40px;
}
.ref-title {
  font-family: 'Addington-Regular', sans-serif;
  font-size: 30px;
  color: #B25325;
  text-align: center;
}
.ref-text {
  font-size: 15px;
  font-weight: bold;
  color: #B25325;
  text-align: left;
}
.ref-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.player-v {
  width: 100% !important;
  /*height: 739px !important;*/
  height: calc( 100vw / 1.717 ) !important;
}
.form-logo {
  background-color: #fcf7eb;
  padding: 20px;
  width: 200px;
}
.form-logo-container {
  position: relative;
  top: -100px;
}
.contactForm {
  position: relative;
  top: -50px;
}









.section {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.section-title-icon {
  width: 20px;
  margin-right: 15px;
}
.section-title {
  font-family: 'Addington-Regular', sans-serif;
  color: #B25325 !important;
  font-size: 55px !important;
}
.section-desc {
  font-family: 'Avenir Next', sans-serif;
  color: #000 !important;
  font-size: 17px !important;
  padding: 30px;
  line-height: 30px;
}
.section-inner-scroll {
  position: relative;
  height: 650px;
  /*overflow-y: scroll;*/
}
.form-container {
  position: relative;
}
.slide-logo {
  position: relative;
  background: transparent;
}
.slide-form {
  position: relative;
  background-color: transparent;
  border: 2px solid #B25325;
  padding: 50px 100px 0px 100px;
  text-align: left;
  width: 90%;
}
.label-custom {
  font-family: 'Avenir Next', sans-serif;
  font-weight: bold;
  font-size: 15x;
  text-transform: uppercase;
  text-align: left;
  color: #B25325;
}
.carousel-item-custom {
  height: 427px;
}
.slideRightToLeft {
  position: absolute;
  width: 100%;
  top: -50%;
  background-color: transparent;
  margin-left: 110%;
  transition: all 0.5s;
}
.slideRightToLeft.show {
  display: block;
  margin-left: 0;
}
.trazo-carousel {
  width: 70%;
}
.trazo-container {
  position: relative;
  padding: 0 !important;
}
.trazo-style {
  width: 101%;
}
.do-title {
  font-family: 'Argon-Oblique', sans-serif;
  font-size: 50px;
  text-align: right;
  text-transform: uppercase;
  color: #000;
}
.do-desc {
  font-family: 'Argon-Regular', sans-serif;
  font-size:15px;
  text-align: right;
  color: #5C5C57;
  width: 80%;
  padding-bottom: 12px;
  opacity: 0;
}
.do-line {
  padding-bottom: 19px;
  width: 100%;
}
.do-arrow {
  position: relative;
  width: 20px;
  cursor: pointer;
}
.do-arrow-float {
  position: absolute;
  top: 0;
}
.do-row {
  height: 155px;
  position: relative;
}
.do-line-container {
  position: absolute; 
  bottom: 0
}
.do-arrow-container {
  position: absolute; 
  top: 15px
}
.do-col {
  /*position: relative;*/
  width: 80px !important;
}
.do-img {
  width: 93%;
}
.build-text-float {
  position: absolute; 
  bottom: 10px; 
  width: 45%;
}

.step-names {
  position: absolute;
  left: 40px;
  top: 46px;
}
.step-slider {
  position: absolute;
  left: 0px;
  top: 50px;
}
.proyect-custom-height {
  height: 100px;
}
.proyect-header {
  font-family: 'Fractul-Medium', sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  color: #595A5C
}
.proyect-title {
  font-family: 'Fractul-Medium', sans-serif;
  font-size: 35px;
  text-align: left;
  color: #000
}
.proyect-desc {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 16px;
  text-align: left;
  padding-right: 25px;
  color: #5C5C57
}
.proyect-img {
  width: 100%;
}
.proyect-container {
  max-width: 1320px !important;
}
.proyect-sub-container {
  position: relative;
  left: 10%;
  padding-right: 10% !important;
}
.gallery-img {
  width: 100%;
}
.gallery-container-1 {
  height: 370px;
}
.gallery-container-2 {
  height: 624px;
}
.map-desc {
  font-family: 'Argon-Oblique', sans-serif;
  font-size: 15px;
  text-align: right;
  color: #5C5C57;
}
.map-image {
  width: 100%;
}
.contact-container {
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}
.contact-title {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #000
}
.contact-social-icons {
  cursor: pointer;
  height: 20px;
}
.contact-icon {
  width: 15px;
}
.contact-info {
  font-family: 'Argon-Regular', sans-serif;
  font-size: 14px;
  text-align: left;
  color: #000
}
.footer {
  padding: 30px !important;
  background-color: #B25325;
}
.footer_logo {
  height: 40px;
}
.footer-icon {
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
}
.footer-text {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.footer-text:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.text-linear-down {
  animation: growDown 0.5s linear;
  font-size: 30px;
  padding-bottom: 4px;
}
.text-linear-up {
  animation: growUp 0.5s linear;
  font-size: 50px;
}

@keyframes growDown {
  0% {
    font-size: 50px;
  }
  50% {
    font-size: 40px;
  }
  100% {
    font-size: 30px;
  }
}
@keyframes growUp {
  0% {
    font-size: 30px;
  }
  50% {
    font-size: 40px;
  }
  100% {
    font-size: 50px;
  }
}

@media (max-width: 1180px) {
  .nav-link {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 820px) {
  .slide-form {
    padding: 50px 50px 0px 50px;
  }
  .form-logo {
    width: 130px;
  }
}

@media (max-width: 460px) {
  .nav-link {
    font-family: 'Argon-Regular', sans-serif !important;
    font-size: 10px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .modal-dialog, .modal-content {
    background-color: transparent !important;
    border: 0 !important;
  }
  .navbar-collapse {
    background-color: transparent !important;
  }
  #service_0, #service_1, #service_3 {
    position: relative;
  }
  .container-depts{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .depts-legend{
    font-size: 15px;
    line-height: 20px;
  }
  .depts-comma-open {
    width: 20px;
    right: 5px;
    bottom: 0;
  }
  .depts-comma-close {
    width: 20px;
    left: 5px;
    bottom: 0;
  }
  .contact-img {
    width: 70px;
  }
  .slide-form {
    padding: 50px 50px 0px 50px;
  }
  .mp-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .footer {
    padding: 20px !important;
  }
  .ref-text {
    text-align: center;
  }
  .ref-desc {
    text-align: center;
  }
  .depts-list {
    padding-left: 0px;
  }
  .portfolio-gall-container {
    padding-top: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 5px !important;
  }
  .portfolio-gallery-img {
    width: 100%;
    height: calc( 100vw / 1.017 );
  }








  .home-section {
    padding: 57px 0 30px 0!important;
  }
  .header-title {
    font-size: 10px;  
  }
  .header-img {
    width: 30%;
  }
  .section {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .section-title {
    font-size: 30px !important;
  }
  .section-desc {
    font-size: 15px !important;
    padding: 10px 30px;
    text-align: center;
  }
  .logo {
    width: 50%;
  }
  .slide-form {
    width: 100%;
  }
  .trazo-carousel {
    width: 95%;
  }
  .carousel-item-custom {
    height: auto;
  }
  .do-title {
    font-family: 'Argon-Oblique', sans-serif;
    font-size: 20px;
    text-align: right;
    text-transform: uppercase;
    color: #000;
}
  .text-linear-up {
    animation: growUp 0.5s linear;
    font-size: 20px;
  }
  .text-linear-down {
    animation: growDown 0.5s linear;
    font-size: 13px;
    padding-bottom: 4px;
  }
  .do-line {
    padding-bottom: 0px;
  }
  .do-row {
    height: 70px;
  }
  .build-text-float {
    position: relative;
    top: 0;
    width: 100%;
  }
  .do-desc {
    font-size: 13px;
    text-align: center;
    width: 100%;
    padding-bottom: 0;
  }
  .do-img {
    width: 100%;
  }
  .do-arrow-float {
    position: relative;
    top: 0;
    padding-bottom: 10px;
  }
  .proyect-custom-height {
    height: 35px;
  }
  .proyect-sub-container {
    position: relative;
    left: 0;
    padding-right: 15px !important;
  }
  .footer_logo {
    height: 50px;
  }

  @keyframes growDown {
    0% {
      font-size: 20px;
    }
    50% {
      font-size: 15px;
    }
    100% {
      font-size: 13px;
    }
  }
  @keyframes growUp {
    0% {
      font-size: 13px;
    }
    50% {
      font-size: 15px;
    }
    100% {
      font-size: 20px;
    }
  }
}

@media (max-width: 280px) {
  .section-title {
    font-size: 18px !important;
  }
  .form-logo {
    width: 154px;
}
}